var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SessionFrame',[_c('TmFormStruct',{attrs:{"submit":_vm.onSubmit.bind(this)}},[_c('h2',{staticClass:"session-title"},[_vm._v(" Recover with backup code ")]),_c('div',{staticClass:"session-main"},[_c('TmFormGroup',{attrs:{"error":_vm.$v.$error && _vm.$v.fields.importName.$invalid,"field-id":"import-name","field-label":"Account Name"}},[_c('TmField',{attrs:{"id":"import-name","type":"text","placeholder":"Must have at least 5 characters","vue-focus":"vue-focus"},model:{value:(_vm.fields.importName),callback:function ($$v) {_vm.$set(_vm.fields, "importName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"fields.importName"}}),(_vm.$v.fields.importName.$error && !_vm.$v.fields.importName.required)?_c('TmFormMsg',{attrs:{"name":"Name","type":"required"}}):_vm._e(),(
            _vm.$v.fields.importName.$error && !_vm.$v.fields.importName.minLength
          )?_c('TmFormMsg',{attrs:{"name":"Name","type":"minLength","min":"5"}}):_vm._e()],1),_c('TmFormGroup',{attrs:{"error":_vm.$v.$error && _vm.$v.fields.importPassword.$invalid,"field-id":"import-password","field-label":"Password"}},[_c('TmField',{attrs:{"id":"import-password","type":"password","placeholder":"Must be at least 10 characters"},model:{value:(_vm.fields.importPassword),callback:function ($$v) {_vm.$set(_vm.fields, "importPassword", $$v)},expression:"fields.importPassword"}}),(
            _vm.$v.fields.importPassword.$error &&
              !_vm.$v.fields.importPassword.required
          )?_c('TmFormMsg',{attrs:{"name":"Password","type":"required"}}):_vm._e(),(
            _vm.$v.fields.importPassword.$error &&
              !_vm.$v.fields.importPassword.minLength
          )?_c('TmFormMsg',{attrs:{"name":"Password","type":"minLength","min":"10"}}):_vm._e()],1),_c('TmFormGroup',{attrs:{"error":_vm.$v.$error && _vm.$v.fields.importPasswordConfirm.$invalid,"field-id":"import-password-confirmation","field-label":"Confirm Password"}},[_c('TmField',{attrs:{"id":"import-password-confirmation","type":"password","placeholder":"Enter password again"},model:{value:(_vm.fields.importPasswordConfirm),callback:function ($$v) {_vm.$set(_vm.fields, "importPasswordConfirm", $$v)},expression:"fields.importPasswordConfirm"}}),(
            _vm.$v.fields.importPasswordConfirm.$error &&
              !_vm.$v.fields.importPasswordConfirm.sameAsPassword
          )?_c('TmFormMsg',{attrs:{"name":"Password confirmation","type":"match"}}):_vm._e()],1),_c('TmFormGroup',{attrs:{"error":_vm.$v.$error && _vm.$v.fields.importSeed.$invalid,"field-id":"import-seed","field-label":"Backup code"}},[_c('FieldSeed',{attrs:{"id":"import-seed","value":_vm.fields.importSeed,"placeholder":"Must be exactly 24 words"},on:{"input":function (val) { return (_vm.fields.importSeed = val); }}}),(_vm.$v.fields.importSeed.$error && !_vm.$v.fields.importSeed.required)?_c('TmFormMsg',{attrs:{"name":"Seed","type":"required"}}):(
            _vm.$v.fields.importSeed.$error && !_vm.$v.fields.importSeed.words24
          )?_c('TmFormMsg',{attrs:{"name":"Seed","type":"words24"}}):_vm._e()],1)],1),_c('div',{staticClass:"session-footer"},[_c('TmBtn',{attrs:{"value":"Recover"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }